<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :elevation="hover ? 12 : 2"
      class="car-feature-card mx-auto"
      max-width="344"
      outlined
      hover
      @click="$emit('edit-carfeature', id)"
    >
      <v-list-item two-line>
        <v-list-item-avatar
          class="mt-8"
          tile
          size="50"
        >
          <v-img contain :src="image"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <!-- <div class="text-overline mb-4">
            {{ type }}
          </div> -->
          <v-list-item-title class="text-h7 mb-1">
            {{ featureName }}
          </v-list-item-title>
              <!-- <v-list-item-subtitle> Status - {{ isActive ? "Active": "Inactive" }}</v-list-item-subtitle> -->
          <v-list-item-subtitle class="last-modified d-flex mt-1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" x-small>{{ icons.mdiCalendarBlankOutline }}</v-icon>
              </template>
              <span>Last Modified</span>
            </v-tooltip>
            <div class="ml-1">{{ updatedOn }}</div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <div>
          <v-icon class="status-icon" x-small :color="isActive ? 'success' : 'error'">{{ icons.mdiCircle }}</v-icon>
          <v-icon class="ml-4 edit-icon" small>{{ icons.mdiPencil }}</v-icon>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import { mdiCircle, mdiPencil, mdiCalendarBlankOutline } from '@mdi/js'

export default {
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String | undefined,
      required: true,
    },
    image: {
      type: String | undefined,
      required: true,
    },
    featureName: {
      type: String,
      required: true,
    },
    updatedOn: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    return {
      icons: {
        mdiCircle,
        mdiPencil,
        mdiCalendarBlankOutline,
      },
    }
  },
}
</script>

<style scoped lang="scss">
.car-feature-card {
  .status-icon {
    position: absolute;
    cursor: pointer;
    left: 10px;
    top: 5px;
  }

  .edit-icon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 5px;
    color: rgb(88, 88, 88);
  }

  .last-modified {
    font-size: 0.7rem;
  }
}
</style>
