<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary"> {{ id ? "Update" : "Add" }} Car Feature</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="carFeatureData.description"
            @keyup="() => carFeatureData.description = capitalizeSentence(carFeatureData.description)"
            outlined
            dense
            :rules="[validators.required]"
            label="Feature Name *"
            placeholder="Feature Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-file-input
            accept="image/*"
            prepend-inner-icon="$file"
            prepend-icon=""
            label="Feature Icon *"
            placeholder="Feature icon"
            outlined
            dense
            v-model="carFeatureData.featureicon"
            @change="onFileChange"
          ></v-file-input>

          <v-avatar tile size="80" v-if="carFeatureData.featureicon">
            <v-img contain :src="imageUrl"></v-img>
          </v-avatar>

          <v-switch
            v-model="carFeatureData.active"
            inset
            :color="carFeatureData.active ? 'success' : 'error'"
            :label="`${carFeatureData.active ? 'Active' : 'Inactive'}`"
          ></v-switch>

          <v-alert dense text color="error" v-if="error">
            {{ error }}
          </v-alert>

          <v-btn
            color="primary"
            class="me-3"
            :loading="loading"
            type="submit"
          >
            {{ id ? "Update" : "Add" }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetcarFeatureData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCircle } from '@mdi/js'
import { find } from 'lodash'
import { ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import useCarFeature from './useCarFeature'
import { capitalizeSentence } from '@core/utils/filter'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    id: {
      type: String | null,
      required: true,
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const fileRules = [v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required']
    const valid = ref(false)
    const form = ref(null)
    const imageUrl = ref('')

    const { addCarFeature, carFeatures, carFeatureData, blankCarFeatureData, error, loading, editCarFeature } =
      useCarFeature()

    const validate = () => {
      form.value.validate()
    }

    const resetForm = async () => {
      await form.value.reset()
      carFeatureData.value = JSON.parse(JSON.stringify(blankCarFeatureData))
    }

    const resetcarFeatureData = () => {
      resetForm()
      error.value = null
      emit('reset-id')
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        if (props.id) {
          await editCarFeature(props.id)
        } else {
          await addCarFeature()
        }
        if (!error.value) {
          resetcarFeatureData()
        }
      } else {
        validate()
      }
    }

    const onFileChange = file => {
      if (!file) {
        return
      }
      createImage(file)
    }

    const createImage = file => {
      const reader = new FileReader()

      reader.onload = e => {
        imageUrl.value = e.target.result
      }
      reader.readAsDataURL(file)
    }

    watch(
      () => props.id,
      () => {
        if (props.id) {
          const carFeature = find(carFeatures.value, ['id', props.id])
          carFeatureData.value.active = carFeature.active
          carFeatureData.value.description = carFeature.description
          carFeatureData.value.featureicon = carFeature.featureicon
          imageUrl.value = carFeature.featureicon
        }
      },
      { immediate: true },
    )

    watch(
      () => props.isAddNewUserSidebarActive,
      () => {
        if (!props.isAddNewUserSidebarActive) {
          resetcarFeatureData()
        }
      },
    )

    return {
      resetcarFeatureData,
      form,
      onSubmit,
      carFeatureData,
      valid,
      validate,
      error,
      fileRules,
      imageUrl,
      onFileChange,
      loading,
      capitalizeSentence,
      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiCircle,
      },
    }
  },
}
</script>
