import useCommon from '@/composables/useCommon'
import useFirestoreCollectionNames from '@/composables/useFirestoreCollectionNames'
import { ref, watch } from '@vue/composition-api'
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
  writeBatch,
} from 'firebase/firestore'
import { chunk, filter, indexOf, isNil, orderBy as sortOrder } from 'lodash'
import useImageKit from './../../../composables/useImageKit'
import { db } from '/src/firebase/config'

export default function useCarFeatureList() {
  const { stringContainsAny } = useCommon()
  const { carFeatureCollectionName, carFleetCollectionName } = useFirestoreCollectionNames()

  const featureCollection = collection(db, carFeatureCollectionName.value)
  const blankCarFeatureData = {
    active: true,
    featureicon: null,
    description: '',
  }

  const carFeatureData = ref(JSON.parse(JSON.stringify(blankCarFeatureData)))

  const carFeatures = ref([])
  const filteredCarFeatures = ref([])
  const paginatedCarFeatures = ref([])
  const error = ref(null)
  const page = ref(1)
  const itemsPerPage = ref(16)

  const searchQuery = ref('')
  const statusFilter = ref(true)
  const typeFilter = ref(null)
  const sortBy = ref(null)
  const loading = ref(false)
  let unsubscribe = null
  const options = ref({
    sortBy: ['updatedon'],
    sortDesc: [true],
  })

  const queryOptions = () => {
    const qOptions = []

    const orderByKey = options.value.sortBy[0] ? options.value.sortBy[0] : 'updatedon'
    const order = options.value.sortDesc[0] ? 'desc' : 'asc'
    qOptions.push(orderBy(orderByKey, order))

    return qOptions
  }

  // fetch data
  const fetchCarFeatures = async () => {
    try {
      if (unsubscribe) {
        unsubscribe()
      }
      loading.value = true
      carFeatures.value = []
      filteredCarFeatures.value = []

      const q = query(featureCollection, ...queryOptions())
      unsubscribe = onSnapshot(q, querySnapshot => {
        carFeatures.value = []
        querySnapshot.forEach(doc => {
          carFeatures.value.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        filteredCarFeatures.value = carFeatures.value
        filterCarFeatures()
      })
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const checkIfCarFeatureExist = async () => {
    const q = query(featureCollection, where('description', '==', carFeatureData.value.description))
    const querySnapshot = await getDocs(q)
    return !!querySnapshot.size
  }

  const addCarFeature = async () => {
    try {
      loading.value = true
      error.value = null
      if (!carFeatureData.value.featureicon) {
        throw new Error('Please add feature icon')
      }
      if (await checkIfCarFeatureExist()) {
        throw new Error('Car feature already exist!')
      }
      const { uploadFile } = useImageKit()
      const imageFile = carFeatureData.value.featureicon
      const imageName = carFeatureData.value.featureicon.name
      const tags = [carFeatureData.value.description]
      const fileUrl = await uploadFile(imageFile, imageName, tags, '/feature/')
      const carFeature = {
        ...carFeatureData.value,
        featureicon: fileUrl,
        createdon: serverTimestamp(),
        updatedon: serverTimestamp(),
      }
      await addDoc(featureCollection, carFeature)
    } catch (e) {
      error.value = e.message
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const editCarFeature = async id => {
    try {
      loading.value = true
      error.value = null
      const prevCarFeature = filter(carFeatures.value, carFeature => carFeature.id == id)
      let carFeature = {
        ...carFeatureData.value,
        updatedon: serverTimestamp(),
      }
      if (prevCarFeature.length && prevCarFeature[0].description != carFeature.description) {
        if (await checkIfCarFeatureExist()) {
          throw new Error('Car feature already exist!')
        }
      }
      if (!carFeatureData.value.featureicon) {
        throw new Error('Please add feature icon')
      } else if (carFeatureData.value.featureicon instanceof File) {
        const { uploadFile } = useImageKit()
        const imageFile = carFeatureData.value.featureicon
        const imageName = carFeatureData.value.featureicon.name
        const tags = [carFeatureData.value.description]
        const fileUrl = await uploadFile(imageFile, imageName, tags, '/feature/')
        carFeature = {
          ...carFeature,
          featureicon: fileUrl,
        }
      }
      await batchUpdate(id, prevCarFeature[0], carFeature)
      // const docReference = doc(db, carFeatureCollectionName.value, id)
      // await updateDoc(docReference, carFeature)
    } catch (e) {
      error.value = e.message
      console.log(e)
    } finally {
      loading.value = false
    }
  }

  const batchUpdate = async (featureId, prevFeature, newFeature) => {
    const { description, featureicon } = newFeature
    const featureDocRef = doc(db, carFeatureCollectionName.value, featureId)

    const carFleetCollection = carFleetCollectionName.value
    const queryCarFleet = query(
      collection(db, carFleetCollection),
      where('features', 'array-contains', prevFeature.description),
    )

    const batch = writeBatch(db)

    // 1. Update brand
    batch.update(featureDocRef, newFeature)

    // 2. Update Car Fleet
    const carFleetItems = await getDocs(queryCarFleet)
    carFleetItems.forEach(document => {
      const docRef = doc(db, carFleetCollection, document.id)

      const features = document.data().features
      const featuresicon = document.data().featuresicon

      const index = indexOf(features, prevFeature.description)
      features.splice(index, 1, description)
      featuresicon.splice(index, 1, featureicon)

      batch.update(docRef, { features, featuresicon })
    })

    await batch.commit()
  }

  const filterCarFeatures = () => {
    let filteredFeatures = carFeatures.value
    if (searchQuery.value && searchQuery.value.length) {
      filteredFeatures = filter(filteredFeatures, ({ description }) => {
        return stringContainsAny(description, searchQuery.value)
      })
    }

    if (!isNil(statusFilter.value)) {
      filteredFeatures = filter(filteredFeatures, ({ active }) => {
        return statusFilter.value == active
      })
    }

    if (!isNil(sortBy.value)) {
      filteredFeatures = sortOrder(filteredFeatures, [sortBy.value.by], [sortBy.value.order])
    }

    filteredCarFeatures.value = filteredFeatures
  }

  watch([searchQuery, statusFilter, typeFilter, sortBy], () => {
    filterCarFeatures()
  })

  watch([filteredCarFeatures, itemsPerPage], () => {
    const chunkSize = itemsPerPage.value == 'All' ? filteredCarFeatures.value.length : itemsPerPage.value
    paginatedCarFeatures.value = chunk(filteredCarFeatures.value, chunkSize)
    page.value = 1
  })

  const unsubscribeCarFeatures = () => {
    unsubscribe()
  }

  fetchCarFeatures()

  return {
    carFeatures,
    page,
    itemsPerPage,
    filteredCarFeatures,
    paginatedCarFeatures,
    searchQuery,
    statusFilter,
    typeFilter,
    sortBy,
    loading,
    carFeatureData,
    blankCarFeatureData,
    error,

    unsubscribeCarFeatures,
    addCarFeature,
    editCarFeature,
  }
}
